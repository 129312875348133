<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="products-collapse"
      role="tab"
      data-toggle="collapse"
      @click="visible = !visible"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ $t('offersOnRequest') }}
      </h4>
    </b-card-header>
    <b-collapse
      id="products-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body v-if="offers.length">
        <OpenOfferList :offers="offers" />
      </b-card-body>
      <b-card-body v-else>
        <h6>You haven't received any offers yet. Please wait a little longer.</h6>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import OpenOfferList from '@/views/apps/components/OpenOfferList.vue';
import {
  BCard, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue';

export default {
  name: 'TradingRequestOffers',
  components: {
    OpenOfferList,
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      offers: [],
    };
  },
  watch: {
    requestId: {
      immediate: true,
      handler() {
        if (this.requestId) {
          this.fetchOffers();
        }
      },
    },
  },
  methods: {
    async fetchOffers() {
      this.offers = await this.$store.dispatch('requests/fetchOffersById', this.requestId);
    },
  },
};
</script>
