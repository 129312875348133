<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="products-collapse"
      role="tab"
      data-toggle="collapse"
      @click="visible = !visible"
    >
      <h4>
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="products-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body>
        <trading-product-item
          v-for="product in products"
          :key="product.id"
          :is-preview-mode="true"
          :product="product"
        />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCollapse,
} from 'bootstrap-vue';
import TradingProductItem from '@/views/trading/trading-add-item/TradingProductItem.vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
    TradingProductItem,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {},
    products: {
      type: Array,
      default: (() => []),
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
