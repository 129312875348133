<template>
  <b-card no-body>
    <b-card-header class="">
      <div class="d-flex justify-content-between w-100 flex-wrap align-items-center">
        <h4 class="font-weight-bold m-0">
          {{ $t('request') }}
        </h4>
        <span class="text-primary font-weight-bold">
          {{ offerId }}
        </span>

        <b-badge
          :variant="resolveRequestStatus(offer.status).variant"
          class="px-1"
        >
          {{ resolveRequestStatus(offer.status).status }}
        </b-badge>
      </div>
    </b-card-header>
    <b-card-body>
      <div v-if="additionalInfo.length">
        <template v-for="(info, index) in additionalInfo">
          <b-row
            :key="index"
            class="additional-info"
          >
            <span>{{ info }}</span>
          </b-row>
        </template>
      </div>
      <b-row>
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('requestType') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left text-capitalize mb-1">
            <template v-if="offer.type">
              {{ offer.type }}
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('desiredRequestTerm') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left mb-1">
            {{ resolveDate(offer.delivery_datetime) || '-' }}
          </div>
        </b-col>
      </b-row>
      <b-row v-if="offer.note">
        <b-col cols="8">
          <div class="text-right mb-1">
            {{ $t('comments') }}:
          </div>
        </b-col>
        <b-col cols="4">
          <div class="text-left mb-1">
            {{ offer.note }}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BAvatar, BBadge, BRow, BCol,
} from 'bootstrap-vue';
import useOffersList from '@/views/trading/trading-offers/useOffersList';
import useRequestsList from './useRequestsList';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BAvatar,
    BBadge,
    BRow,
    BCol,
  },
  setup() {
    const {
      getOfferInfo,
      resolveDate,
    } = useOffersList();
    const {
      resolveRequestStatus,
    } = useRequestsList();
    return {
      getOfferInfo,
      resolveDate,
      resolveRequestStatus,
    };
  },
  props: {
    offer: {
      type: Object,
      default: (() => {}),
    },
    offerId: {
      type: String,
      default: '',
    },
    additionalInfo: {
      type: Array,
      default: (() => []),
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.company-card-header {
  text-transform: uppercase;
}

.company-card-country {
  font-size: 12px;
}

.additional-info {
  margin: 0 0 7px;
  padding: 3px 5px;
  width: 100%;
  background-color: rgba(238, 238, 253, 0.67);
  border-radius: 4px;
  font-size: 10px;
}
</style>
