<template>
  <div>
    <section class="request-add-wrapper">
      <b-row class="request-add">
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <trading-request-offers
            v-if="request.status > 1"
            :request-id="request.id"
          />
          <trading-preview-products
            :title="$t('products')"
            :products="request.products || []"
          />
          <trading-deal-comments
            v-if="showDealComments"
            :title="$t('comments')"
            :comment-owner="request.last_comment || {}"
            :comment-request="noteRequest"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <trading-request-preview-deal
            :offer="request"
            :offer-id="requestId"
            :additional-info="requestAdditionalInfo"
          />
          <b-card>
            <div class="d-flex flex-column">
              <b-button
                v-if="isAllowVerify"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mb-1"
                variant="primary"
                @click="verify"
              >
                {{ $t('verify') }}
              </b-button>
              <template v-if="currentRequest.deal">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="toViewDeal(currentRequest.deal.id)"
                >
                  {{ $t('goToDeal') }}
                </b-button>
              </template>
              <template v-else>
                <b-tooltip
                  v-if="isCreateOfferBtnDisabled"
                  :title="createOfferBtnTooltip"
                  target="createOfferButton"
                  triggers="hover"
                />
                <div
                  v-if="showCreateOfferBtn"
                  id="createOfferButton"
                  class="tooltip-button-div mb-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isCreateOfferBtnDisabled || isCreateOfferBtnLoading"
                    @click="createOffer"
                  >
                    {{ $t('createAnOffer') }}
                  </b-button>
                </div>

                <b-button
                  v-if="isRequestEditable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :to="{ name: 'trading-requests-add', params: { param: requestId } }"
                >
                  {{ $t('edit') }}
                </b-button>
              </template>
              <b-tooltip
                v-if="!isRequestRemovable"
                :title="deleteBtnTooltip"
                target="deleteButton"
                triggers="hover"
              />
              <div
                v-if="!isSupplierOnly"
                id="deleteButton"
                class="tooltip-button-div mt-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="!isRequestRemovable"
                  variant="outline-danger"
                  @click="handleDeleteRequestClick"
                >
                  {{ $t('deleteRequest') }}
                </b-button>
              </div>
              <b-button
                class="mt-1"
                variant="outline-secondary"
                :to="{ name: 'trading-requests-list', params: { param: isUserAdmin || isSupplierOnly ? 'all' : 'own' } }"
              >
                {{ $t('backToRequests') }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <confirm-modal
      :modal-visible="modalVisible"
      :modal-message="String($t('deleteRequestConfirmMessage', {id: currentRequest.id}))"
      :ok-text="String($t('yesDelete'))"
      :cancel-text="String($t('noCancel'))"
      :is-ok-button-spinning="isOkButtonSpinning"
      @modal-hidden="modalVisible = false"
      @yes-clicked="deleteRequest"
      @no-clicked="modalVisible = false"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BTooltip,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TradingDealComments from '@/views/trading/trading-deals/TradingDealComments.vue';

import Ripple from 'vue-ripple-directive';
import TradingRequestOffers from '@/views/trading/trading-requests/TradingRequestOffers.vue';
import TradingRequestPreviewDeal from '@/views/trading/trading-requests/TradingRequestPreviewDeal.vue';
import TradingPreviewProducts from '@/views/trading/trading-requests/TradingPreviewProducts.vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import router from '@/router';
import axios from '@/libs/axios';

export default {
  components: {
    BTooltip,
    TradingRequestOffers,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TradingPreviewProducts,
    TradingDealComments,
    TradingRequestPreviewDeal,
    confirmModal,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    request: {
      note: '',
      id: 0,
      delivery_datetime: null,
      delivery_type: null,
      type: null,
      type_term: null,
      warranty: null,
      created_at: null,
      updated_at: null,
      country_id: '',
      products: [],
      budget: '',
      can_edit: false,
    },
    editVerify: false,
    requestId: '',
    modalVisible: false,
    isOkButtonSpinning: false,
    isCreateOfferBtnLoading: false,
  }),
  computed: {
    currentRequest() {
      const { currentRequest } = this.$store.state.requests;
      return currentRequest;
    },
    noteRequest() {
      const isHaveRequest = !!this.currentRequest;
      let note = {};
      if (isHaveRequest) {
        note = {
          note: this.currentRequest?.note,
          user: {
            ...this.currentRequest?.profile,
            company_name: this.currentRequest?.createdBy?.company.company_name,
            date: this.currentRequest?.created_at,
            role: 'buyer',
          },
        };
      }
      return note;
    },
    isUserAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    isSupplierOnly() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
    isBuyerOnly() {
      return this.$store.getters['profile/isBuyerOnly'];
    },
    showDealComments() {
      return this.isUserAdmin && this.$route.params.id !== 'new';
    },
    isAllowVerify() {
      return this.$store.getters['profile/isAdmin']
          && (this.request.status >= 1 && this.request.status < 4)
          && !this.$route.params.id !== 'new';
    },
    dataToSend() {
      return {
        note: this.request.note,
        product_ids: this.request.products.map(item => item.id),
        country_id: this.request.country_id,
        owned_by: this.$store.state.profile.profile.id,
        delivery_datetime: this.request.delivery_datetime,
        type: this.request.type,
        budget: this.request.budget,
        type_term: this.request.type_term,
      };
    },
    isRequestEditable() {
      return this.currentRequest?.can_edit;
    },
    isRequestRemovable() {
      return this.currentRequest?.can_delete;
    },
    isAllowCreateOffer() {
      const { profile } = this.$store.state.profile;
      if (!this.isUserAdmin && (profile && profile.id === this.currentRequest?.user?.id)) {
        return false;
      }

      return this.currentRequest.status > 1;
    },
    deleteBtnTooltip() {
      if (this.currentRequest?.deal) {
        return this.$t('dealCreatedFromRequest');
      }

      return this.$t('offersAddedToRequest');
    },
    showCreateOfferBtn() {
      return !this.isBuyerOnly;
    },
    isCreateOfferBtnDisabled() {
      return !this.isAllowCreateOffer;
    },
    createOfferBtnTooltip() {
      if (!this.isRequestEditable) {
        if (this.currentRequest.deal) {
          return this.$t('dealCreatedFromRequest');
        }
        return this.$t('offersAddedToRequest');
      }

      if (!this.isAllowCreateOffer) {
        if (this.currentRequest.status <= 1) {
          return this.$t('requestNotAccepted');
        }

        const { profile } = this.$store.state.profile;
        if (!this.isUserAdmin && (profile && profile.id === this.currentRequest?.user?.id)) {
          return this.$t('cannotCreateOfferForYourRequest');
        }

        return this.$t('cannotCreateOfferForRequest');
      }

      return this.$t('cannotCreateOfferForRequest');
    },
    requestAdditionalInfo() {
      const info = [];

      if (this.currentRequest.deal) {
        info.push(this.$t('dealCreatedFromRequest'));
      } else if (!this.isRequestEditable) {
        info.push(this.$t('offersAddedToRequest'));
      }

      return info;
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler: 'fetchRequest',
    },
  },
  methods: {
    async fetchRequest() {
      try {
        if (this.$route.params.id && this.$route.params.id !== 'new') {
          await this.$store.dispatch('requests/fetchRequestById', this.$route.params.id);
          this.request = {
            ...this.request,
            ...this.currentRequest,
            country_id: this.currentRequest?.country?.id || '',
          };
          this.requestId = `#${this.request.id}`;
        }
        if (this.$route.params.id && this.$route.params.id === 'new') {
          this.requestId = this.$t('new');
        }
      } catch {
        this.$router.go(-1);
      }
    },
    updateDeliveryTerm(date) {
      this.request.delivery_datetime = date;
    },
    updateProducts(data) {
      this.request.products = [...this.request.products, data];
    },
    async save() {
      if (this.request.products.length === 0) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please add products.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      if (this.$route.params.id === 'new') {
        const { data } = await this.$http.post('/requests', this.dataToSend);

        await this.$router.push({ name: this.$router.currentRoute.name, params: { id: data.data.request.id } });
      } else {
        await this.$store.dispatch('requests/updateRequest', {
          requestId: this.$route.params.id,
          payload: this.dataToSend,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('changesSaved'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      }
    },
    async publish() {
      await this.save();
      await this.$http.post(`/requests/publish/${this.$route.params.id}`);
    },
    async verify() {
      await this.$http.post(`/requests/verify/${this.$route.params.id}`);
      await this.$store.dispatch('requests/fetchRequestById', this.$route.params.id);
      this.request = {
        ...this.request,
        ...this.currentRequest,
        country_id: this.currentRequest?.country?.id || '',
      };
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'The request verified successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    async createOffer() {
      this.isCreateOfferBtnLoading = true;

      try {
        const { data: { data } } = await this.$http.post(`/requests/by-offer/${this.$route.params.id}`);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('offerFromRequestMessage', { id: this.$route.params.id }),
            icon: 'EditIcon',
            variant: 'success',
          },
        });

        await this.$router.push({ name: 'trading-offers-add', params: { id: data.offer.id } });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.isCreateOfferBtnLoading = false;
      }
    },
    cancel() {
      this.request = { ...this.currentRequest };
    },
    setNote(data) {
      this.request.note = data;
    },
    toViewDeal(id) {
      this.$router.push({ name: 'trading-deals-add', params: { id } });
    },
    handleDeleteRequestClick() {
      this.modalVisible = true;
    },
    async deleteRequest() {
      this.isOkButtonSpinning = true;
      await axios.delete(`/v1/requests/${this.currentRequest.id}/delete`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('requestDeletedSuccessfully', { id: this.currentRequest.id }),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        router.push({ name: 'trading-requests-list', params: { param: this.isUserAdmin || this.isSupplierOnly ? 'all' : 'own' } });
        this.modalVisible = false;
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }).finally(() => {
        this.isOkButtonSpinning = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

.tooltip-button-div {
  width: 100%;

  button {
    width: 100%;
  }
}
</style>
